'use strict'

import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de.js'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import { english as English } from 'flatpickr/dist/l10n/default.js'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import { Spanish } from 'flatpickr/dist/l10n/es.js'

const languagesMapping = {
  de: German,
  en: English,
  es: Spanish,
  fr: French,
  it: Italian,
  nl: Dutch
}

class CalendarManager {
  constructor () {
    if (!CalendarManager.instance) {
      this.calendars = {}
      CalendarManager.instance = this

      this.initCalendar = this.initCalendar.bind(this)

      // https://github.com/flatpickr/flatpickr/issues/1634
      window.addEventListener('touchstart', function (e) {
        if (document.querySelector('.flatpickr-calendar.open') && !e.target.closest('.flatpickr-calendar') && e.target !== document.body) {
          document.body.click()
        }
      })
    }

    return CalendarManager.instance
  }

  initCalendar (id, el, config, language) {
    if (!this.calendars[id]) {
      config.locale = languagesMapping[language]

      this.calendars[id] = flatpickr(el, config)
    }

    return this.calendars[id]
  }

  getCalendar (id) {
    return this.calendars[id]
  }

  removeCalendar (id) {
    if (this.calendars[id]) {
      delete this.calendars[id]
    }

    return true
  }
}

const instance = new CalendarManager()
Object.freeze(instance)

export default instance
