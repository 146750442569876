'use strict'

const common = require('core/common')
const ApplicationDateCalendar = require('core/modules/applicationDateCalendar')

const bookingCancellationSimulation = {
  init: function () {
    const dateField = document.getElementById('cancellation_commit_applicationDate')

    dateField.addEventListener('change', (e) => {
      const loaderOverlay = document.querySelector('#simulation-card .loader-overlay')
      const xhr = common.createXhr(
        'GET',
        dateField.getAttribute('data-simulation-url') + '?applicationDate=' + dateField.value
      )

      xhr.addEventListener('readystatechange', function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.response)
            document.getElementById('simulation-card').innerHTML = data.cardView
            document.getElementById('cancellation_commit_proposalId').value = data.proposalId
          } else if (xhr.status >= 400) {
            common.showFlashMessage('Error')
          }
          loaderOverlay.classList.remove('display-overlay')
        }
      })
      loaderOverlay.classList.add('display-overlay')

      xhr.send()
    })
    this.initCalendar()
  },

  initCalendar: function () {
    this.calendar = new ApplicationDateCalendar(document.getElementById('cancellation_commit_applicationDate'))
  }
}

module.exports = bookingCancellationSimulation.init()
