'use strict'

module.exports = {
  init: function () {
    this.initFormFields()
  },

  initFormFields: function () {
    const focusBlockFn = function () {
      const formBlock = this.closest('.aba-form-block')
      if (formBlock) {
        formBlock.classList.add('aba-form-block--focused')
      }
    }
    const unfocusBlockFn = function () {
      const formBlock = this.closest('.aba-form-block')
      if (formBlock) {
        formBlock.classList.remove('aba-form-block--focused')
      }
    }

    document.querySelectorAll('.aba-form-input__field').forEach(function (el) {
      el.addEventListener('click', focusBlockFn)
      el.addEventListener('focus', focusBlockFn)
      el.addEventListener('focusout', unfocusBlockFn)

      el.addEventListener('blur', function () {
        const formBlock = this.closest('.aba-form-block')

        if (formBlock) {
          formBlock.classList.remove('aba-form-input__field--focused')

          setTimeout(() => {
            if (this.value === '') {
              formBlock.classList.remove('aba-form-input__field--filled')
            } else {
              formBlock.classList.add('aba-form-input__field--filled')
            }
          }, 150)
        }
      })
    })

    document.querySelectorAll('.aba-form-input-display-plain-password').forEach(function (el) {
      el.addEventListener('click', function () {
        const inputId = this.getAttribute('data-input-id')
        if (inputId) {
          const input = document.getElementById(inputId)
          input.type = input.type === 'text' ? 'password' : 'text'
        }
      })
    })
  }
}
