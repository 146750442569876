// @codingStandardsIgnoreFile
'use strict'

const mediaQueries = MEDIA_QUERIES

module.exports = {
  polyfills: function () {
    require('intersection-observer')
    require('mdn-polyfills/NodeList.prototype.forEach')
    require('mdn-polyfills/Object.assign')
    require('mdn-polyfills/Object.values')
    require('mdn-polyfills/CustomEvent')
    require('mdn-polyfills/Element.prototype.matches')
    require('mdn-polyfills/Element.prototype.closest')
    require('mdn-polyfills/Array.prototype.find')
    require('mdn-polyfills/Array.prototype.includes')
    require('mdn-polyfills/Array.from')

    return true
  },

  initLazyLoading: function (selector = 'img.lazy') {
    const lazyImages = document.querySelectorAll(selector)

    if ('IntersectionObserver' in window) {
      const lazyImageObserver = new window.IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const lazyImage = entry.target

            if (lazyImage.getAttribute('data-src')) {
              lazyImage.src = lazyImage.getAttribute('data-src')
              lazyImage.removeAttribute('data-src')
            }

            if (lazyImage.getAttribute('data-srcset')) {
              lazyImage.srcset = lazyImage.getAttribute('data-srcset')
              lazyImage.removeAttribute('data-srcset')
            }

            lazyImage.classList.remove('lazy')
            lazyImage.classList.add('lazy-loaded')
            lazyImageObserver.unobserve(lazyImage)
          }
        })
      })

      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage)
      })
    }
  },

  getMediaQuerySize: function (size) {
    return mediaQueries.sizes[size] || null
  },

  queryMedia: function (size) {
    if (mediaQueries.sizes[size]) {
      return window.matchMedia('(max-width: ' + mediaQueries.sizes[size] + ')')
    }

    return false
  },

  formatCurrency: function (number, currency = 'EUR', hideDecimals = false) {
    const config = {
      currency,
      minimumFractionDigits: 0,
      style: 'currency'
    }

    if (number % 1 !== 0) {
      config.minimumFractionDigits = 2
    }

    if (hideDecimals) {
      config.maximumFractionDigits = 0
    }

    const locale = document.querySelector('html').lang
    return new Intl.NumberFormat(locale, config).format(number)
  },

  formatDate: function (value) {
    var date = new Date(value)
    var locale = document.querySelector('html').lang

    return date.toLocaleDateString(locale)
  },

  createXhr: function (verb, url) {
    const xhr = new XMLHttpRequest()
    xhr.open(verb, url)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    if (verb === 'POST') {
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    }

    return xhr
  },

  showFlashMessage: function (message, type = 'danger', timeout = 10 * 1000) {
    const buttonEl = document.createElement('button')
    buttonEl.className = 'close'
    buttonEl.innerText = '×'
    buttonEl.setAttribute('type', 'button')
    buttonEl.setAttribute('data-target', 'alert-flash')

    const messageEl = document.createElement('p')
    messageEl.innerText = message

    const alertEl = document.createElement('div')
    alertEl.className = 'alert alert-' + type
    alertEl.appendChild(buttonEl)
    alertEl.appendChild(messageEl)

    const alertContainerEl = document.createElement('div')
    alertContainerEl.setAttribute('id', 'alert-flash')
    alertContainerEl.appendChild(alertEl)

    document.body.insertBefore(alertContainerEl, document.body.firstChild)

    document.addEventListener('click', function (e) {
      if (e.target.matches('#alert-flash .close')) {
        const alertEl = document.getElementById('alert-flash')
        if (alertEl) {
          alertEl.parentNode.removeChild(alertEl)
        }
      }
    })

    setTimeout(function () {
      const alertEl = document.getElementById('alert-flash')
      if (alertEl) {
        alertEl.parentNode.removeChild(alertEl)
      }
    }, timeout)
  },

  isMobile: function () {
    return this.queryMedia('small').matches
  },

  isTablet: function () {
    return this.queryMedia('large').matches
  },

  createNodeFromHTML: function (html) {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.firstElementChild
  },

  init: function () {
    const formModule = require('./modules/form')
    formModule.init()

    require('core/modules/language-selector')

    document.addEventListener('click', function (e) {
      const rowLinkEl = e.target.closest('.row-link')
      if (rowLinkEl && !e.target.classList.contains('no-link')) {
        window.document.location = rowLinkEl.querySelector('.row-target').href
      }

      if (e.target.matches('.external-link')) {
        e.preventDefault()
        e.stopPropagation()
        window.open(e.target.href)
      }

      const rowExternalLinkEl = e.target.closest('.row-external-link')
      if (rowExternalLinkEl && !e.target.classList.contains('no-link')) {
        e.preventDefault()
        e.stopPropagation()
        window.open(rowExternalLinkEl.querySelector('.row-target').href)
      }
    })

    document.addEventListener('submit', function (e) {
      if (e.target.classList.contains('prevent-double-submission')) {
        const form = e.target
        const alreadySubmitted = form.getAttribute('data-already-submitted')

        if (!alreadySubmitted) {
          form.setAttribute('data-already-submitted', 1)
          form.querySelectorAll('button[type=submit]').forEach(function (el) {
            el.classList.add('button-disabled')

            if (e.target.classList.contains('has-loader')) {
              const loader = document.createElement('span')
              loader.classList.add('icon-loading')
              el.appendChild(loader)
            }
          })

          return true
        }

        return false
      }
    })

    document.querySelectorAll('a.has-loader').forEach(item => {
      item.addEventListener('click', () => {
        if (item.getElementsByClassName('icon--loading').length === 0) {
          const previousIcons = Array.from(item.classList).filter((classCss) => {
            return classCss.match('icon--')
          })

          previousIcons.forEach(classCss => {
            item.classList.remove(classCss)
          })
          item.classList.add('icon--loading')
        }
      })
    })

    document.querySelectorAll('[data-toggle-target]').forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        document.querySelectorAll('[data-toggle="' + item.dataset.toggleTarget + '"]').forEach(element => {
          element.classList.toggle('hidden-text')
        })
      })
    })

    window.onpopstate = function (e) {
      if (e.state && e.state.forceRefresh) {
        window.location.href = e.target.location.href
      }
    }

    const body = document.getElementsByTagName('body')[0]
    if (body && body.hasAttribute('data-flash-error')) {
      this.showFlashMessage(body.getAttribute('data-flash-error'))
    }
  }
}
