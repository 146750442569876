// @codingStandardsIgnoreFile
'use strict'

const common = require('core/common')
const EventBus = require('core/modules/eventBus')

require('../../../../core/js/modules/booking')

const stepVacaf = {
  previousRequest: null,

  init: function () {
    const form = document.getElementById('vacaf-subsidy-form')
    form.addEventListener('submit', function (e) {
      e.preventDefault()
      if (this.previousRequest && this.previousRequest.readyState < XMLHttpRequest.DONE) {
        this.previousRequest.abort()
      }
      const xhr = common.createXhr('POST', form.getAttribute('action'))

      xhr.addEventListener('readystatechange', function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.response)
            EventBus.emit('booking-changed', data)
            form.querySelector('#validate-vacaf').classList.remove('disabled')
          } else if (xhr.status >= 400) {
            common.showFlashMessage('Error')
          }
        }
      })

      const ajaxData = {
        'vacaf[cafNumber]': form.querySelector('input[name="vacaf[cafNumber]"]').value,
        'vacaf[cafDepartment]': form.querySelector('select[name="vacaf[cafDepartment]"]').value,
        'vacaf[vacafBookingNumber]': form.querySelector('input[name="vacaf[vacafBookingNumber]"]').value,
        'vacaf[cafSubsidyAmount]': form.querySelector('input[name="vacaf[cafSubsidyAmount]"]').value
      }

      xhr.send(Object.keys(ajaxData).map(key => `${key}=${ajaxData[key]}`).join('&'))

      this.previousRequest = xhr
    })
  }
}

module.exports = stepVacaf.init()
