// @codingStandardsIgnoreFile
'use strict'

const common = require('core/common')
const Autocomplete = require('core/modules/autocomplete')

const homepage = {
  previousRequest: null,
  previousRequestCgos: null,

  init: function () {
    const codeInputEl = document.getElementById('checkCode_code')
    const codeInputCgosEl = document.getElementById('checkCodeCgos_code')
    const codeSelect = document.getElementById('member-account-code')

    codeInputEl.addEventListener('keyup', this.toggleCheckCodeSubmit)
    codeInputEl.addEventListener('change', this.toggleCheckCodeSubmit)

    if (codeInputCgosEl) {
      codeInputCgosEl.addEventListener('keyup', this.toggleCheckCodeCgosSubmit)
      codeInputCgosEl.addEventListener('change', this.toggleCheckCodeCgosSubmit)
    }

    document.getElementById('member-form-toggle').addEventListener('click', function (e) {
      e.preventDefault()

      document.getElementById('member-form-collapse').classList.toggle('active')
    })

    document.getElementById('member-account-code').addEventListener('change', function () {
      codeInputEl.value = codeSelect.value
      codeInputEl.dispatchEvent(new CustomEvent('change'))
    })

    codeInputEl.dispatchEvent(new CustomEvent('change'))

    if (codeInputCgosEl) {
      codeInputCgosEl.dispatchEvent(new CustomEvent('change'))
    }

    if (document.getElementById('autocomplete-agency')) {
      new Autocomplete('#autocomplete-agency', { // eslint-disable-line no-new
        callbackSelect: function (value) {
          let url = this.inputEl.getAttribute('data-account-url')
          url = url + (url.indexOf('?') === -1 ? '?' : '&') + 'agencyId=' + encodeURIComponent(value.id)

          const xhr = common.createXhr('GET', url)

          xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              const data = JSON.parse(xhr.response)
              let accountOptions = codeSelect.firstElementChild.outerHTML

              for (let i = 0; i < data.data.length; i++) {
                accountOptions = accountOptions + '<option value="' + data.data[i].name + '">' + data.data[i].name + '</option>'
              }

              codeSelect.innerHTML = accountOptions
              codeSelect.disabled = data.data.length === 0
            }
          }

          xhr.send(null)
        }
      })
    }

    const checkCodeCgosForm = document.querySelector('form[name=checkCodeCgos]')
    if (checkCodeCgosForm) {
      checkCodeCgosForm.addEventListener('submit', function (e) {
        if (document.activeElement.id === 'checkCodeCgos_submitCatalog') {
          return
        }
        if (this.previousRequestCgos && this.previousRequestCgos.readyState < XMLHttpRequest.DONE) {
          this.previousRequestCgos.abort()
        }
        document.getElementById('cgos-member-block').classList.add('hidden')
        document.querySelector('.member-form-cgos .alert').classList.add('hidden')

        const url = e.target.getAttribute('data-url')
        const ajaxData = {
          code: e.target.querySelector('input').value
        }

        const xhr = common.createXhr('POST', url)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            const data = JSON.parse(xhr.response)
            if (data.error !== false) {
              document.querySelector('.member-form-cgos .alert').classList.remove('hidden')
              return
            }
            document.getElementById('cgos-member-block').classList.remove('hidden')
            document.querySelector('#cgos-beneficiary span').textContent = data.name
            document.querySelector('#cgos-quotient span').textContent = data.quotient
            document.querySelector('#cgos-quotient-description span[data-description]').textContent = data.description
            document.querySelector('#cgos-quotient-description span.homepage-cgos').textContent = data.code
          }
        }

        xhr.send(Object.keys(ajaxData).map(key => `${key}=${ajaxData[key]}`).join('&'))

        e.preventDefault()
      })
    }
  },

  toggleCheckCodeSubmit: function () {
    const el = document.getElementById('checkCode_submit')
    const el2 = document.getElementById('checkCode_submit2')
    const code = document.getElementById('checkCode_code').value

    el.disabled = !code
    el2.disabled = !code
  },

  toggleCheckCodeCgosSubmit: function () {
    const el = document.getElementById('checkCodeCgos_submit')
    const code = document.getElementById('checkCodeCgos_code').value

    el.disabled = !code
  }
}

module.exports = homepage.init()
