// @codingStandardsIgnoreFile
'use strict'

require('../../css/app.css')

const Autocomplete = require('core/modules/autocomplete')
const common = require('core/common')

document.addEventListener('DOMContentLoaded', function () {
  const currentPageType = document.body.getAttribute('data-pagetype')

  if (currentPageType) {
    switch (currentPageType) {
      case 'homepage':
        require('../pages/homepage')
        break
      case 'booking-step-vacaf':
        require('../pages/step-vacaf')
        break
      case 'booking-display':
        require('../pages/booking-display')
        break
      case 'booking-cancellation-simulation':
        require('../pages/booking-cancellation-simulation')
        break
      case 'booking-cancellation-refund':
        require('../pages/booking-cancellation-refund')
        break
      case 'booking-add-options':
      case 'booking-cancellation-fees-payment':
        require('../pages/booking-add-options')
        break
      default:
        break
    }
  }

  const cart = require('../modules/cart')

  const queriedCatalogIds = []
  const searchOffersCatalogEl = document.getElementById('search_offers_catalog')

  if (searchOffersCatalogEl) {
    const preSelectedCatalogId = searchOffersCatalogEl.value

    if (preSelectedCatalogId) {
      queriedCatalogIds.push(preSelectedCatalogId)
    }
  }

  if (document.getElementById('autocomplete-catalog')) {
    new Autocomplete('#autocomplete-catalog', { // eslint-disable-line no-new
      callbackSelect: function (value) {
        document.getElementById('search_offers_catalog').value = value.value
        document.querySelector('#autocomplete-catalog input').value = value.name

        document.querySelectorAll('#search-form-content [data-catalog-id]').forEach(function (el) {
          el.style.display = 'none'
        })

        if (queriedCatalogIds.indexOf(value.value) === -1) {
          const xhr = common.createXhr('POST', document.getElementById('autocomplete-catalog').getAttribute('data-callback-url'))

          xhr.addEventListener('readystatechange', function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              queriedCatalogIds.push(value.value)

              cart.empty()
            }
          })
          xhr.send('catalogId=' + value.value)
        } else {
          const catalog = document.querySelector('#search-form-content [data-catalog-id="' + value.value + '"]')

          if (catalog) {
            catalog.style.display = 'block'
          }
        }
      },
      dataElement: '#search_offers_catalog',
      dataSource: 'select'
    })
  }

  if (document.getElementById('autocomplete-member')) {
    /* eslint-disable no-new */
    new Autocomplete('#autocomplete-member')
  }
})
