// @codingStandardsIgnoreFile
'use strict'

const languageSelector = {
  init: function () {
    const languageSelectors = document.getElementsByClassName('language-selector')

    for (let i = 0; i < languageSelectors.length; i++) {
      languageSelectors[i].addEventListener('change', function (e) {
        const option = this.options[this.selectedIndex]

        window.location = option.getAttribute('data-url')
      })
    }
  }
}

module.exports = languageSelector.init()
