// @codingStandardsIgnoreFile
'use strict'

const Modal = require('core/modules/modal')

const bookingDisplay = {
  init: function () {
    const bookingContainer = document.querySelector('.booking[data-offer-not-available-message]')
    if (bookingContainer) {
      const checkOfferMessage = bookingContainer.getAttribute('data-offer-not-available-message')

      const modal = new Modal({})
      modal.setContent(checkOfferMessage)
      modal.open()

      document.getElementById('btn-close').addEventListener('click', function () {
        modal.close()
      })
    }

    document.querySelectorAll('a[data-copy]').forEach(item => {
      item.addEventListener('click', () => {
        const el = document.createElement('textarea')
        el.value = item.getAttribute('data-copy')
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        item.parentNode.querySelector('span').style.display = 'inline'
      })
    })
  }
}

module.exports = bookingDisplay.init()
