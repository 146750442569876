import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

class Adyen {
  constructor (environment, clientKey, returnUrl) {
    this.environment = environment
    this.clientKey = clientKey
    this.returnUrl = returnUrl
  }

  async createAdyenCheckout (sessionId, sessionData, dropInDivId) {
    const sessionConfiguration = this.initCheckoutSessionConfiguration(sessionId, sessionData)
    const checkout = await AdyenCheckout(sessionConfiguration)
    checkout.create('dropin').mount(`#${dropInDivId}`)
  }

  initCheckoutSessionConfiguration (sessionId, sessionData) {
    return {
      environment: this.environment,
      clientKey: this.clientKey,
      session: {
        id: sessionId,
        sessionData: sessionData
      },
      onPaymentCompleted: (result, component) => {
        let url = this.returnUrl
        url = url + (url.indexOf('?') === -1 ? '?' : '&')
        url = url + 'sessionId=' + component.props.session.id + '&resultCode=' + result.resultCode

        window.location.href = url
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component)
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false
        }
      }
    }
  }
}
export default Adyen
