'use strict'

const common = require('core/common')
const EventBus = require('core/modules/eventBus')

function updateInsurance (insuranceAmount) {
  const element = document.querySelector('[data-insurance="default-insurance"] .insurance-price')

  if (element) {
    element.innerHTML = common.formatCurrency(insuranceAmount)
  }
}

function updateRecap (recap) {
  const element = document.querySelector('.recap-wrapper')

  if (element) {
    element.innerHTML = recap
    EventBus.emit('booking-recap-updated')
  }
}

function updateCreditCodesItems (creditCodesItems) {
  const element = document.querySelector('.credit-code-form .items')

  if (element) {
    element.innerHTML = creditCodesItems
    EventBus.emit('booking-credit-code-updated')
  }
}

EventBus.on('booking-changed', ({ recap, insuranceAmount, creditCodesItems }) => {
  if (undefined !== recap) {
    updateRecap(recap)
  }

  if (undefined !== insuranceAmount) {
    updateInsurance(insuranceAmount)
  }

  if (undefined !== creditCodesItems) {
    updateCreditCodesItems(creditCodesItems)
  }
})

EventBus.on('booking-recap-updated', () => {
  document.querySelectorAll('.discount-code-remove').forEach(function (el) {
    el.addEventListener('click', function (e) {
      e.preventDefault()
      const xhr = common.createXhr('POST', el.getAttribute('data-url'))
      xhr.addEventListener('readystatechange', function () {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const data = JSON.parse(xhr.response)
          EventBus.emit('booking-changed', data)
        }
      })
      xhr.send()
    })
  })
})
