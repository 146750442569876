'use strict'

const common = require('../common')

const defaultConfig = {
  callbackClose: function () {},
  callbackEmptyInput: function () {},
  callbackOpen: function () {},
  callbackPreRender: function (resultEl) {
    return resultEl
  },
  callbackSelect: function () {},
  minLength: 2,
  queryParameter: 'query',
  delay: 300,
  dataSource: 'xhr',
  dataElement: null,
  closeIfMinLengthBelow: true,
  formatLabel: function (result) {
    return result.name
  }
}

class Autocomplete {
  constructor (element, userConfig = {}) {
    if (typeof element === 'string') {
      this.element = document.querySelector(element)
    } else {
      this.element = element
    }

    if (!this.element) {
      return
    }

    this.config = Object.assign({}, defaultConfig, userConfig)
    this.config.callbackClose = this.config.callbackClose.bind(this)
    this.config.callbackEmptyInput = this.config.callbackEmptyInput.bind(this)
    this.config.callbackOpen = this.config.callbackOpen.bind(this)
    this.config.callbackPreRender = this.config.callbackPreRender.bind(this)
    this.config.callbackSelect = this.config.callbackSelect.bind(this)

    this.events = {}
    this.cache = {}
    this.inputEl = this.element.querySelector('.autocomplete-field')
    this.resultsEl = this.element.querySelector('.autocomplete-results')
    this.selectedRow = -1
    this.searchValue = this.sanitize(this.inputEl.value)
    this.previousRequest = null
    this.resultsVisible = false
    this.onKeyUpTimeout = null

    if (
      this.config.dataSource === 'select' &&
      this.config.dataElement
    ) {
      this.initSelectEl()
    }

    document.body.addEventListener('click', e => {
      if (!this.element.contains(e.target) && this.resultsVisible) {
        this.close()
      }
    })

    this.initEvents()
  }

  initEvents () {
    this.events.inputClick = () => {
      if (this.cache[this.searchValue] && !this.resultsVisible) {
        this.displayResults(this.cache[this.searchValue])
      } else if (this.inputEl.value !== '' && this.searchValue !== this.inputEl.value) {
        this.query()
      }
    }

    this.events.inputKeyDown = e => {
      if (
        e.keyCode === 13 &&
        this.resultsVisible
      ) {
        e.preventDefault() // prevent form for submitting if selecting result with enter key
      }
    }

    this.events.inputKeyUp = e => {
      const rows = this.resultsEl.getElementsByClassName('autocomplete-result')

      if (
        e.keyCode === 38 &&
        this.selectedRow > -1 &&
        this.resultsVisible
      ) {
        rows[this.selectedRow--].classList.remove('autocomplete-result-focus')

        if (this.selectedRow > -1) {
          rows[this.selectedRow].classList.add('autocomplete-result-focus')
          this.inputEl.value = rows[this.selectedRow].getAttribute('data-value')
        }
      } else if (
        e.keyCode === 40 &&
        this.selectedRow < rows.length - 1 &&
        this.resultsVisible
      ) {
        if (this.selectedRow > -1) {
          rows[this.selectedRow].classList.remove('autocomplete-result-focus')
        }

        rows[++this.selectedRow].classList.add('autocomplete-result-focus')
        this.inputEl.value = rows[this.selectedRow].getAttribute('data-value')
      } else if (
        e.keyCode === 13 &&
        this.resultsVisible
      ) {
        if (rows[this.selectedRow]) {
          rows[this.selectedRow].click()
        }
      } else if (e.keyCode === 27) {
        if (this.resultsVisible) {
          this.close()
        }
      } else if (this.config.minLength <= this.inputEl.value.trim().length && this.searchValue !== this.inputEl.value) {
        this.resultsEl.innerHTML = ''
        this.searchValue = this.sanitize(this.inputEl.value)
        this.selectedRow = -1

        if (this.cache[this.searchValue]) {
          this.displayResults(this.cache[this.searchValue])
        } else {
          clearTimeout(this.onKeyUpTimeout)

          this.onKeyUpTimeout = setTimeout(() => {
            if (this.previousRequest && this.previousRequest.readyState < XMLHttpRequest.DONE) {
              this.previousRequest.abort()
            }

            if (this.config.minLength <= this.inputEl.value.trim().length && this.searchValue !== '') {
              this.query()
            }
          }, this.config.delay)
        }
      } else if (this.config.minLength > this.inputEl.value.trim().length) {
        this.searchValue = this.sanitize(this.inputEl.value)

        if (this.resultsVisible && this.config.closeIfMinLengthBelow) {
          this.close()
        } else {
          this.hide()
        }

        if (this.searchValue.length === 0) {
          this.resultsEl.innerHTML = ''
          this.config.callbackEmptyInput()
        }
      }
    }

    this.inputEl.addEventListener('touchstart', this.events.inputClick)
    this.inputEl.addEventListener('click', this.events.inputClick)
    this.inputEl.addEventListener('keyup', this.events.inputKeyUp)
    this.inputEl.addEventListener('keydown', this.events.inputKeyDown)
  }

  initSelectEl () {
    if (typeof this.config.dataElement === 'string') {
      this.selectEl = document.querySelector(this.config.dataElement)
    } else {
      this.selectEl = this.config.dataElement
    }

    if (!this.selectEl) {
      console.error('Data source element not found.')
    }

    if (this.selectEl.nodeName !== 'SELECT') {
      console.error('Data source is not a select.')
    }

    if (this.selectEl.options[this.selectEl.selectedIndex].value) {
      this.inputEl.value = this.selectEl.options[this.selectEl.selectedIndex].text
    }
  }

  query () {
    let url = this.inputEl.getAttribute('data-url')
    const loadingMessage = this.inputEl.getAttribute('data-loading-message')

    this.resultsVisible = true

    if (loadingMessage && !this.resultsEl.querySelector('.autocomplete-result-message')) {
      const loadingEl = document.createElement('div')
      loadingEl.className = 'autocomplete-result-message'
      loadingEl.innerHTML = loadingMessage
      this.resultsEl.appendChild(loadingEl)

      this.open()
    }

    if (this.config.dataSource === 'xhr' && url) {
      const queryStringParams = []
      const params = this.getQueryParameters()
      for (const name in params) {
        if (!Object.prototype.hasOwnProperty.call(params, name)) {
          continue
        }
        queryStringParams.push(name + '=' + encodeURIComponent(params[name]))
      }
      url = url + (url.indexOf('?') === -1 ? '?' : '&') + queryStringParams.join('&')

      const xhr = common.createXhr('GET', url)

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          try {
            const data = JSON.parse(xhr.response)

            if (xhr.status === 200) {
              this.displayResults(data)
              this.cache[this.searchValue] = data
            } else if (xhr.status === 400 && data.error) {
              this.displayError(data.error)
            }
          } catch (e) { }
        }
      }

      xhr.send(null)

      this.previousRequest = xhr
    } else if (this.config.dataSource === 'select') {
      let data = []

      try {
        data = this.parseSelectDataSource().filter(obj => {
          return obj.name.match(new RegExp(this.searchValue, 'i'))
        })
      } catch (exception) {
        data = []
      }

      this.displayResults({
        data: data
      })
    }
  }

  getQueryParameters () {
    const params = {}
    params[this.config.queryParameter] = this.searchValue

    return params
  }

  parseSelectDataSource () {
    const data = []
    const options = this.selectEl.getElementsByTagName('option')

    for (var i = 0, j = options.length; i < j; i++) {
      data.push({
        value: options[i].value,
        name: options[i].text,
        dataset: options[i].dataset
      })
    }

    return data
  }

  displayResults (data) {
    this.resultsEl.innerHTML = ''
    const noResultsMessage = this.inputEl.getAttribute('data-no-results-message')

    this.resultsVisible = true

    if (data.data && Array.isArray(data.data) && data.data.length > 0) {
      data.data.forEach(value => {
        let resultEl = document.createElement('div')
        resultEl.className = 'autocomplete-result'
        if (value.dataset) {
          Object.assign(resultEl.dataset, value.dataset)
        }
        resultEl.setAttribute('data-value', value.name)
        if (Object.prototype.hasOwnProperty.call(value, 'highlight')) {
          if (`${value.type}Number` in value.highlight) {
            resultEl.innerHTML = `${value.name} (${value.highlight[`${value.type}Number`]})`
          } else {
            resultEl.innerHTML = value.highlight[`${value.type}Name`] || value.name
          }
        } else {
          resultEl.innerHTML = value.name
        }

        resultEl.addEventListener('click', () => {
          this.inputEl.value = this.config.formatLabel(value)
          this.selectedRow = -1

          if (this.config.callbackSelect) {
            this.config.callbackSelect(value)
          }

          this.close()
        })

        resultEl.addEventListener('mouseover', () => {
          const focusedEl = this.resultsEl.getElementsByClassName('autocomplete-result-focus')[0]
          if (focusedEl) {
            focusedEl.classList.remove('autocomplete-result-focus')
          }

          this.selectedRow = -1
        })

        if (this.config.callbackPreRender) {
          resultEl = this.config.callbackPreRender(resultEl, value)
        }

        this.resultsEl.appendChild(resultEl)
      })

      this.open()
    } else if (noResultsMessage) {
      const noResultEl = document.createElement('div')
      noResultEl.className = 'autocomplete-result-message'
      noResultEl.innerHTML = noResultsMessage
      this.resultsEl.appendChild(noResultEl)

      this.open()
    }
  }

  displayError (error) {
    this.resultsEl.innerHTML = ''
    this.resultsVisible = true

    const errorEl = document.createElement('div')
    errorEl.className = 'autocomplete-result-message'
    errorEl.innerHTML = error
    this.resultsEl.appendChild(errorEl)

    this.open()
  }

  hide () {
    this.resultsEl.classList.add('autocomplete-results-hidden')
  }

  open () {
    this.resultsEl.classList.remove('autocomplete-results-hidden')

    if (this.config.callbackOpen) {
      this.config.callbackOpen()
    }
  }

  close () {
    this.hide()
    this.resultsVisible = false

    if (this.config.callbackClose) {
      this.config.callbackClose()
    }
  }

  sanitize (content) {
    const node = new window.DOMParser().parseFromString(content, 'text/html')
    return node.body.textContent || ''
  }
}

module.exports = Autocomplete
