const subscriptions = {}

function on (eventType, callback) {
  if (!subscriptions[eventType]) {
    subscriptions[eventType] = []
  }
  subscriptions[eventType].push(callback)
}

function emit (eventType, arg) {
  if (!subscriptions[eventType]) {
    return
  }
  subscriptions[eventType].forEach(callback => callback(arg))
}

module.exports = { emit, on }
