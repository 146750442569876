// @codingStandardsIgnoreFile
'use strict'

class Modal {
  constructor (config = {}) {
    const fadeEl = document.createElement('div')
    fadeEl.className = 'modal-fade'
    fadeEl.tabIndex = '0'

    if (config.class) {
      const classNames = config.class.split(' ')

      for (let i = 0, j = classNames.length; i < j; i++) {
        fadeEl.classList.add(classNames[i])
      }
    }

    const modalEl = document.createElement('div')
    modalEl.className = 'modal-content'

    const closeEl = document.createElement('button')
    closeEl.type = 'button'
    closeEl.className = 'close modal-close'
    closeEl.innerHTML = '&times;'

    if (config.closeText) {
      closeEl.innerHTML = config.closeText
    }

    const bodyEl = document.createElement('div')
    bodyEl.className = 'modal-body'

    this.closeEl = modalEl.appendChild(closeEl)
    this.bodyEl = modalEl.appendChild(bodyEl)

    fadeEl.appendChild(modalEl)

    this.element = document.body.appendChild(fadeEl)
  }

  initEvents () {
    this.element.querySelectorAll('.modal-close').forEach(el => {
      el.addEventListener('click', () => {
        this.close()
      })
    })

    this.element.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.close()
      }
    })
    this.element.addEventListener('click', e => {
      if (e.target.classList.contains('modal-fade')) {
        this.close()
      }
    })
  }

  open () {
    document.body.classList.add('modal-open')
    this.element.classList.add('visible')
    this.element.focus()

    this.element.dispatchEvent(new CustomEvent('modal.opened'))

    return this
  }

  close () {
    document.body.classList.remove('modal-open')
    this.element.classList.remove('visible')

    if (this.originalParentEl) {
      this.originalParentEl.appendChild(this.bodyEl.firstChild)
      this.originalParentEl = null
    }

    this.element.dispatchEvent(new CustomEvent('modal.closed'))

    return this
  }

  setContent (content) {
    if (typeof content === 'string') {
      this.bodyEl.innerHTML = content
    } else {
      this.originalParentEl = content.parentNode
      this.bodyEl.appendChild(content)
    }

    this.initEvents()

    return this
  }

  destroy () {
    document.body.removeChild(this.element)
  }
}

module.exports = Modal
