// @codingStandardsIgnoreFile
'use strict'
const Adyen = require('core/modules/adyen').default

const bookingAddOptions = {
  init: function () {
    if (document.getElementById('dropin-container')) {
      this.initAdyen()
    }
  },
  initAdyen: function () {
    const dropInContainer = document.getElementById('dropin-container')
    const adyenService = new Adyen(
      dropInContainer.dataset.environment,
      dropInContainer.dataset.clientKey,
      dropInContainer.dataset.sessionReturnUrl
    )
    adyenService.createAdyenCheckout(dropInContainer.dataset.sessionId, dropInContainer.dataset.sessionData, 'dropin-container')
  }
}

bookingAddOptions.init()
module.exports = bookingAddOptions
